
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuNav",
  data() {
    return {
      isScrolled: false,
      isOpen: false,
    };
  },
  mounted() {
    this.isScrolled = window.scrollY >= 100 ? true : false;

    document.addEventListener("scroll", () => {
      this.isScrolled = window.scrollY >= 100 ? true : false;
    });
  },
  computed: {
    isSamePage(): boolean {
      return this.$route.name === "home";
    },
  },
  methods: {
    scrollTo(path: string) {
      this.isOpen = false;
      if (!this.isSamePage) this.$router.push("/");

      setTimeout(() => {
        const el = document.getElementById(path);
        if (el) {
          el.scrollIntoView({
            block: "start",
            behavior: "smooth",
            inline: "start",
          });
        }
      }, 100);
    },
  },
});
