import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/objednat',
    name: 'order',
    component: () => import('../views/OrderView.vue')
  },
  {
    path: '/uz-se-to-pece',
    name: 'success',
    component: () => import('../views/SuccessView.vue')
  },
  {
    path: '/anketa',
    name: 'survey',
    component: () => import('../views/SurveyView.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
