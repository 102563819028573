
import { defineComponent } from "vue";
import PageHeadComponent from "@/components/PageHeadComponent.vue";

export default defineComponent({
  name: "NotFound",
  components: {
    PageHeadComponent,
  },
});
