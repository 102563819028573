/* eslint-disable @typescript-eslint/no-explicit-any */
import { IItemModel } from "@/models/IItem.model";
import { IOrderModel } from "@/models/IOrder.model";
import { ISurveyModel } from "@/models/ISurvey.model";
import axios from "axios";
import { RequestService } from "./request.service";

const apiBaseDev =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/"
    : "https://api.kupsibagetu.cz/api/";

interface DefaultResponse {
  success?: boolean;
}

const reqService = new RequestService(axios);

export default {
  async postOffer(model: IOrderModel): Promise<DefaultResponse> {
    const url = apiBaseDev + "order/";
    return reqService.processRequestWithModel("post", url, model);
  },
  async getBaguetteTypes(): Promise<IItemModel[]> {
    const url = apiBaseDev + "items/baguettes";
    return reqService.processRequest("get", url);
  },
  async getBaguetteFlours(): Promise<IItemModel[]> {
    const url = apiBaseDev + "items/flours";
    return reqService.processRequest("get", url);
  },
  async getCurrentSurvey(): Promise<ISurveyModel> {
    const url = apiBaseDev + "surveys/get-current";
    return reqService.processRequest("get", url);
  },
  async putVoteSurvey(surveyId: string, optionId: string): Promise<boolean> {
    const url = apiBaseDev + "surveys/vote";
    return reqService.processRequestWithModel("put", url, {
      surveyId: surveyId,
      optionId: optionId,
    });
  },
};
