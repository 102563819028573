/* eslint-disable @typescript-eslint/no-explicit-any */
import { Axios, AxiosError } from "axios";

enum RequestErrorCode {
  OfferAlreadyExist = "Objednávka byla dnes již podána",
  BadRequest = "Nastala chyba, zkuste to později",
  UndefinedError = "Nastala neznámá chyba, zkuste to později",
  MissingSomeFields = "Některé pole ve formuláři nebyla vyplněna",
}

export class RequestService {
  constructor(private _HTTP: Axios) {}
  public processRequestWithModel(
    method: string,
    url: string,
    model: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._HTTP
        .request({
          method: method,
          url: url,
          data: model,
        })
        .then(
          (item: any) => {
            resolve(item.data);
          },
          (error: AxiosError) => {
            reject(error);

            if (error.response) {
              this.dialogResolver(error.response.data);
            }
          }
        );
    });
  }

  /** Proccess request and refresh token if necesary */
  public processRequest(method: string, url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._HTTP
        .request({
          method: method,
          url: url
        })
        .then(
          (item: any) => {
            resolve(item.data);
          },
          (error: AxiosError) => {
            reject(error);

            if (error.response) {
              this.dialogResolver(error.response.data);
            }
          }
        );
    });
  }

  dialogResolver(errorCode: any) {
    const error =
      RequestErrorCode[errorCode as keyof typeof RequestErrorCode] ||
      RequestErrorCode.UndefinedError;
    const popup = document.createElement("div");
    popup.setAttribute("class", "popup-error-message");
    const popupMessage = document.createElement("p");
    popupMessage.innerText = error;
    popup.appendChild(popupMessage);
    document.body.appendChild(popup);

    setTimeout(() => {
      popup.setAttribute("class", "popup-error-message show-popup");
    }, 100);

    setTimeout(() => {
      popup.setAttribute("class", "popup-error-message hide-popup");
    }, 3400);

    setTimeout(() => {
      document.body.removeChild(popup);
    }, 3500);
  }
}
