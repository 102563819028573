
import { defineComponent } from "vue";
import MenuNav from "@/components/MenuNav.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    MenuNav,
    LoadingComponent,
    FooterComponent,
  },
  data() {
    return{
      showLoading: false, // <--- Maybe remove loading?
      showComponents: true, // <--- Maybe remove loading?
      fadeOutLoading: false,
      isBackofficeView: false
    }
  },
  mounted(){
    setTimeout(() => {
      this.showComponents = true;
    }, 2500);
    setTimeout(() => {
      this.fadeOutLoading = true;
    }, 3700);
    setTimeout(() => {
      this.showLoading = false;
    }, 4000);
  }
})
