
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeadComponent",
  props: {
    title: String,
    image: String,
  }
});
