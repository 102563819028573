
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingComponent",
  props: {
    hide: {
      default: false,
      type: Boolean
    }
  }
});
